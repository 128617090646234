@import url('https://fonts.googleapis.com/css?family=Lato:300i,400,400i,700,700i,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Cairo:200,300,400,600,700,900&display=swap');


* {
   box-sizing: border-box;
   margin: 0;
}

:root {
   --bg-primary: #358DCE;
   --bg--lightBlue: #5db1ec;
   --bg-orange: #ff9914;
   --bg-orageLight: #ffdb9a;
   --bg-offwhite: #bebebe;
   --bg-body: rgb(251, 255, 255);
   --bg-body3: #eaf0f7;
   --bg-body2: rgb(236, 222, 236);
   --bg-offlightwhite: #eee9;
   --bg-white: #fff;
   --bg-red: red;
   --bg-shadow: rgb(207, 219, 243);
   --bg-gray: gray;
   --bg-black: #000;
   --bg-darkBlue: #002c58;
   --bg-eee: #eee;
   --bg-ccc: #ccc;
}

.bg-primary,
.bg-primary:hover {
   background-color: var(--bg--lightBlue) !important;
}

.bg-red {
   background-color: var(--bg-red);
}

.bg-yellow {
   background-color: var(--bg-orageLight);
}

.cursor-pointer {
   cursor: pointer;
}

body {
   background-color: var(--bg-body3);
   font-size: 14px;
   font-family: 'Lato', sans-serif;
}

h6 {
   font-size: 14px;
   font-family: 'Lato', sans-serif;
}

a {
   color: var(--bg-primary);
}

input::-ms-input-placeholder,
input::placeholder {
   font-size: 10px;
}


#root {
   position: relative;
}

.container-fluid {
   max-width: 1400px;
}

.form-control:focus {
   border-color: #ccc;
   box-shadow: none;
}

.form-control {
   margin-bottom: 1.3rem;
}

.filter-control {
   width: 100%;
   height: calc(2.25rem + 2px);
   padding: 0.375rem 0.75rem;
   line-height: 1.5;
   color: #495057;
   outline: none;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #ced4da;
   border-radius: 5px;
   /* -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
   transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
   -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; */
}

.filter-control:focus {
   background-color: none;
   background: none;
}

nav {
   background-color: var(--bg-darkBlue);
   height: 50px;
   color: white;
   font-weight: bold;
   /* box-shadow: 0px 3px 9px rgb(207, 219, 243); */
   padding: 8px 10px;
}

.fa-sign-out {
   color: var(--bg-white);
}

nav ul {
   margin: 0;
   display: inline-block;
}

nav ul li {
   list-style: none;
   list-style-type: none;
   display: inline-flex;
   justify-content: center;
   padding: 15px;
   align-items: center;
   position: relative;
}

nav ul li a {
   text-decoration: none;
   font-weight: 600;
   color: #eee;
   font-family: 'Cairo', sans-serif;
   font-size: 15px;
   position: relative;
}

nav ul li a:hover {
   color: var(--bg-orange);
   text-decoration: none;
}

nav ul li.active a {
   color: var(--bg-orange);
}

nav ul li.active::before {
   content: "";
   position: absolute;
   border-bottom: 9px solid var(--bg-body2);
   border-left: 20px solid transparent;
   border-right: 20px solid transparent;
   bottom: 0;
   margin-bottom: -1px;

}

.profil {
   display: inline-flex;
   justify-content: center;
}

.profil div:first-child {
   border-radius: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px double #eee;
   overflow: hidden;
   min-height: 35px;
   min-width: 35px;
   max-height: 35px;
   max-width: 35px;
   margin-right: 5px;
}

.profil div:nth-child(2) {
   display: flex;
   flex-flow: column;
   align-items: flex-start;
   cursor: pointer;
}

.profil div img {
   /* max-height: 100%; */
   max-width: 100%;
}

.profil-d {
   display: flex;
   height: 100%;
   align-items: center;
   position: relative;
}

.profil-d div:first-child {
   border-radius: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px double #eee;
   overflow: hidden;
   min-height: 250px;
   min-width: 250px;
   max-height: 250px;
   max-width: 250px;
   margin-right: 5px;
}

.profil-d-svg {
   position: absolute !important;
   bottom: 0;
   width: 100%;
   text-align: center;
   display: none;
}

.profil-d:hover+.profil-d-svg,
.profil-d-svg:hover {
   display: inline-block;
}

.profil-d-svg svg {
   margin-left: 0 !important;
}

.profil-d-svg>label:active,
.profil-d-svg>span:active {
   background-color: var(--bg-offwhite);
}

aside {
   background-color: var(--bg-darkBlue);
   height: 100%;
   /* margin-right: 1px; */
   /* max-width: 200px; */
   min-width: 150px;

}

aside .aside-login {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 5px 10px;
   min-height: 50px;
}

aside ul {
   margin: 0;
   padding-inline-start: 0;
   padding-inline-end: 0;
   list-style: none;
   padding-top: 15px;
}

aside ul li.has-children a {
   position: relative;
   /* box-shadow: 0px 1px 0px #eee */
}

aside ul li.has-children>a::before {
   position: absolute;
   content: "";
   right: 0;
   top: 0;
   height: 8px;
   width: 8px;
   border: 1px solid;
   border-color: #ccc #ccc transparent transparent;
   margin-right: 20px;
   transform: rotate(45deg);
   margin-top: 23px;
}

aside ul li a {
   padding: 17px 12px;
   margin: 0px 0;
   display: block;
   font-size: 14px;
   color: white;
   position: relative;
}

aside.client ul li a {
   padding: 25px 15px;
}

aside.client ul li.has-children>a::before {
   margin-top: 35px;
}


aside ul li a.active {
   color: var(--bg-orageLight);
   font-weight: 600;
}

aside ul li a.active::before {
   border-color: var(--bg-orageLight) var(--bg-orageLight) transparent transparent;
}

aside ul li a:hover {
   text-decoration: none;
   color: var(--bg-primary);
}

aside ul li .fa {
   margin-right: 15px;
}

aside .a-child {
   padding: 0;
}

aside .a-child a {
   padding: 0 0 10px 50px;
}

.lv_cht_inctr {
   border-radius: 100%;
   background-color: red;
   color: #eee;
   width: 15px;
   height: 15px;
   display: flex;
   justify-content: center;
   font-size: 65%;
   align-items: center;
   position: absolute;
   right: 0;
   bottom: 0;
   margin-right: 10px;
   margin-bottom: 20px;
   z-index: 1;
}

.screen-tab {
   display: inline-flex;
}

.screen-tab>div {
   text-align: center;
   padding: 5px 10px;
   border-bottom: 1px solid var(--bg-gray);
   cursor: pointer;
}

.screen-tab>div:hover {
   text-align: center;
   padding: 5px 10px;
   color: var(--bg-primary);
   border-bottom: 1px solid var(--bg-primary);
   cursor: pointer;
}

.screen-tab>div.active {
   border-bottom: 3px solid var(--bg-red);
   color: var(--bg-red);
   margin-bottom: -1px;
   border-radius: 2px;
}

.usd-prev {
   display: flex;
   flex-direction: column;
}

.usd-prev .usd-title {
   display: flex;
   justify-content: space-between;
   border-bottom: 1px solid var(--bg-offlightwhite);
   padding: 8px 10px 5px;
}

.usd-title h6 {
   margin: 0;
}

.usd-prev-data {
   max-height: 630px;
   overflow: auto;
}

.app_card {
   margin-bottom: 1.3rem;
   border: none;
   box-shadow: 0px 0px 8px var(--bg-shadow);
   /* overflow: auto; */
}

.card_title {
   position: relative;
   padding: 10px 1rem 7px;
   display: flex;
   justify-content: space-between;
   /* border-bottom: 1px solid #eee; */
   align-items: center;
}

.app_card .card-body {
   display: flex;
   flex-flow: column;
   overflow: auto;
}

.card_footer {
   position: relative;
   padding: 10px 1rem 7px;
   justify-content: space-between;
   border-top: 1px solid #eee;
}

.card_title h6,
.c-title {
   font-weight: 600;
   line-height: 1.51;
   letter-spacing: 0.18px;
   color: #011627;
   text-transform: capitalize;
   margin-bottom: 0;
}

.card_filter {
   /* display: inline-flex; */
   text-align: right;
}

.card_title>div svg {
   width: 15px;
   height: 15px;
}

.card_title>div .prefix__cls-1 {
   fill: var(--bg--orange);
}

.card_title>div svg:first-child,
.card_title>div svg:first-child .prefix__cls-1 {
   fill: var(--bg-orange);
   margin-right: 8px;
}

.pagination {
   justify-content: flex-end;
   flex-direction: column;
   padding: 1.25em;
}

.pagination .paging {
   margin-bottom: 0;
   display: flex;
   justify-content: flex-end;
}

.pagination li,
.pagination input {
   height: 30px;
   width: 30px;
   color: #fff;
   margin-right: 2px;
   display: inline-flex;
   justify-content: center;
   align-items: center;
   font-size: 15px;
   border: 1px solid var(--bg-orange);
   outline: none;
   text-align: center;
   border-radius: 3px;
   color: var(--bg-orange);
   cursor: pointer;
}

.pagination input {
   background-color: var(--bg-orange);
   color: #fff;
   cursor: text;
}

.pagination .page-number {
   text-align: right;
   margin-top: 10px;
}


.table-data {
   /* overflow: auto; */
   width: 100%;
   height: 100%;
   /* max-height: 500px; */
   /* min-height: inherit; */
}

.table-data table {
   width: 100%;
}

.table-data table th,
.table-data table td {
   white-space: nowrap;
   padding: 10px;
}

.table-data table tr th,
.table-data table tr td {
   padding-left: 1.25em;
}

.table-data table tr:nth-child(odd) {
   border-top: 1px solid var(--bg-offlightwhite);
   border-bottom: 1px solid var(--bg-offlightwhite);
}

.table-data table th span {
   position: relative;
   display: inline-flex;
   vertical-align: middle;
   margin-left: 15px;
}

.table-data table th span::before {
   position: absolute;
   content: "";
   border-top: 4px solid var(--bg-offwhite);
   border-left: 4px solid transparent;
   border-right: 4px solid transparent;
   right: 0;
   top: 0;
   margin-top: 1px;
}

.table-data table th span::after {
   position: absolute;
   content: "";
   border-bottom: 4px solid var(--bg-offwhite);
   border-left: 4px solid transparent;
   border-right: 4px solid transparent;
   right: 0;
   bottom: 0;
   margin-bottom: 1px;
}

.divtable_header {
   border-top: 1px solid var(--bg-offlightwhite);
   border-bottom: 1px solid var(--bg-offlightwhite);
}

.divtable_body,
.divtable_more {
   padding: 10px 0;
   border-bottom: 1px solid var(--bg-offlightwhite);
}

.divtable_more {
   padding: 10px;
   background-color: var(--bg-body);
}

.divtable_more div div {
   padding: 5px 0;
   font-size: 13px;
}

.divtable_header div {
   padding: 10px;
   font-weight: bold;
}

.main-page {
   display: flex;
}

.main-page>div:first-child {
   flex: 0 0 30%;
   max-width: 225px;
   overflow: hidden;
}

.main-page>div:nth-child(2) {
   width: 100%;
   overflow: auto;
}

.sidebar_pane_width {
   max-width: 35px !important;
}

.breadcrumb {
   padding-top: 8px;
   padding-bottom: 8px;
   /* padding-bottom: 0; */
   box-shadow: 0px 3px 9px rgb(207, 219, 243);
   margin: 0;
   border-radius: 0;
   background-color: var(--bg-body3);
}

.site-logo img {
   max-width: 70px;
}


.header-icon {
   padding-left: 13px;
   text-align: right;
   display: inline-block;
}

.header-icon a {
   font-size: 105%;
   margin-right: 15px;
   color: #fff;
}

.header-icon a:first-child {
   font-size: 120%;
}

.header-icon a:last-child {
   margin-right: 0;
}

.btn-orange {
   background-color: var(--bg-orange);
   color: #fff;
}


.btn-primary {
   background-color: var(--bg-primary);
   border: var(--bg-primary);
}

.btn-primary:hover {
   background-color: var(--bg--lightBlue);
}

.btn-primary:focus,
.btn-primary:hover {
   box-shadow: none;
   border: none;
}

._shadow {
   border: none;
   box-shadow: 0px 0px 2px #ccc;
}

.font-bold {
   font-weight: 600;
}

.text-gray {
   color: gray;
}

.text-green {
   color: green;
}

.text-red {
   color: red !important;
}

.text-blue {
   color: var(--bg-primary) !important;
}

.text-orange {
   color: var(--bg-orange) !important;
}

.inner-h {
   min-height: 600px;
}

.cursor-default {
   cursor: default;
}

.numba-card {
   display: flex;
   flex-direction: row;
   border: none;
   align-items: center;
}

.numba-card h6 {
   font-weight: bold;
}

.numba-icon {
   height: 45px;
   width: 45px;
   margin-right: 10px;
   border-radius: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.numba-icon .fa {
   font-size: 20px;
   color: #fff;
}

.numba-fig {
   font-size: 120%;
   font-weight: bold;
   margin-top: 20px;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.numba-fig small {
   font-size: 60%;
}

.indica {
   height: 15px;
   width: 15px;
   background-color: red;
   display: inline-block;
   border-radius: 100%;
   position: relative;
}

.indica::before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   justify-content: center;
   align-items: center;
   border-left: 3px solid transparent;
   border-right: 3px solid transparent;
   border-top: 4px solid #fff;
   margin-top: 6px;
   margin-left: 4.5px;
}

.indica.add {
   background-color: green;
}

.indica.add::before {
   border-left: 3px solid transparent;
   border-right: 3px solid transparent;
   border-bottom: 5px solid #fff;
   border-top: none;
   margin-top: 4px;
   margin-left: 5px;
}

.chrt-an {
   display: flex;
   justify-content: space-between;
   padding-bottom: 20px;
   min-width: 600px;
}

.chrt-indica1 {
   display: flex;
}

.chrt-idica {
   height: 10px;
   width: 10px;
   border-radius: 100%;
   margin: 0 20px;
   display: inline-block;
}

.stat-initdate {
   position: relative;
   padding: 5px;
   text-align: left;
   min-width: 93px;
   border-radius: 5px;
   border: 1px solid var(--bg-gray);
   cursor: pointer;
}

.stat-initdate::after {
   content: "";
   border-top: 8px solid var(--bg-gray);
   border-left: 7px solid transparent;
   border-right: 7px solid transparent;
   position: absolute;
   right: 0;
   top: 0;
   margin-top: 12px;
   margin-right: 2px;
}


.accc+span {
   display: inline-block;
   height: 20px;
   width: 40px;
   border-radius: 15px;
   border: 1px solid var(--bg-offlightwhite);
   position: relative;
   vertical-align: middle;
   transition: all .5s ease-in-out;

}

.accc+span::before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   display: inline-block;
   height: 18px;
   width: 20px;
   border-radius: 15px;
   background-color: var(--bg-offwhite);
   margin-left: 0px;
   transition: all .5s ease-in-out;
}

.accc:checked+span {
   border: 1px solid var(--bg-primary);
   transition: all .5s ease-in-out;
}

.accc:checked+span::before {
   background-color: var(--bg-primary);
   margin-left: 18px;
}

.accc_skill+span {
   height: 15px;
   width: 40px;
}

.accc_skill+span::before {
   height: 13px;
   margin-left: 1px;
}

.rowtop {
   margin-top: 4rem !important;
}

.table-container {
   overflow: auto;
   height: 100%;
}

.nm_sexxtion {
   display: inline-flex;
   padding-bottom: 14px !important;
   width: 100%;
   cursor: pointer;
}

.nm_sexxtion>div:nth-child(2) {
   padding-left: 10px;
   line-height: 1.2;
}

.nm_sexxtion h6 {
   margin: 0;
   margin-bottom: 3px;
}

.nm_sexxtion:hover {
   background-color: var(--bg-body3);
}

.nm_sexxtion small {
   color: grey;
   font-weight: normal;
   font-size: 87%;
}

.nm_mgsbox {
   width: unset;
   align-items: flex-end;
}

.nm_mgsbox h6 {
   margin: 0;
}

.svg_icon {
   position: relative;
}

.svg_icon svg {
   width: 20px;
   height: 17px;
   margin-left: 10px;
}

.prefix__cls-1 {
   fill: var(--bg-ccc);
   /* fill: grey; */
}

.svg_icon ul {
   position: absolute;
   top: 0;
   right: 0;
   list-style: none;
   padding: 0;
   /* border: 1px solid #ccc; */
   box-shadow: 0px 0px 4px var(--bg-shadow);
   border-radius: 5px;
   margin-bottom: 0;
   z-index: 1;
   min-width: 150px;
}

.svg_icon ul li:first-child {
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
}

.svg_icon ul li:last-child {
   border-bottom-left-radius: 5px;
   border-bottom-right-radius: 5px;
}

.svg_icon ul li {
   padding: 10px 15px;
   background-color: var(--bg-white);
}

.svg_icon ul li {
   padding: 10px 15px;
   background-color: var(--bg-white);
}

.svg_icon ul li:hover {
   background-color: var(--bg-body3);
   font-weight: bold;
   cursor: default;
}

.notify_head {
   margin-bottom: 1rem;
   color: var(--bg-primary);
}

.notify_head small {
   display: block;
}

.all-user-data-body {
   min-height: 500px;
   max-height: 700px;
   overflow: auto;
}

.not-found {
   height: inherit;
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: inherit;
   width: 100%;
   height: 100%;
}

.run_auth {
   width: 100%;
   min-height: 600px;
   display: flex;
   align-items: center;
   justify-content: center;
   /* flex-direction: column; */
}

.newbies_avatar {
   min-width: 40px;
   min-height: 40px;
   display: inline-flex;
   max-width: 40px;
   max-height: 40px;
   justify-content: center;
   align-items: center;
   /* flex: 0 0 50%; */
   border: 1px solid #ccc;
   border-radius: 100%;
   overflow: hidden;
}

.newbies_avatar img {
   max-height: 100%;
   max-width: 100%;
}

.avatar_msgbox {
   min-width: 25px;
   min-height: 25px;
   max-width: 25px;
   max-height: 25px;
   margin-right: 5px;
}

.details-da {
   background-color: #fff;
   border-radius: 5px;
   position: sticky;
   top: 0;
   padding: 0;
}

.details-da .details-data-v {
   height: 100%;
   max-height: 720px;
   overflow: auto;
}

.details-da ._shadow {
   border-top-left-radius: 0;
   border-top-right-radius: 0;
}

.details-da ._shadow:first-child {
   border-radius: 0.25rem;
   border-bottom-left-radius: 0;
   border-bottom-right-radius: 0;
}


.details-da>h6:first-child {
   padding: 10px;
   font-weight: bold;
   color: #000;
   border-bottom: 1px solid var(--bg-offlightwhite);
   margin: 0;
}

.psnal_data {
   padding: 10px;
   border-bottom: 1px solid var(--bg-offlightwhite);
   /* background-color: var(--bg-body); */
}

.psnal_data ._title {
   margin-bottom: 1.3rem;
   color: gray
}

.psnal_data .bio-data {
   display: flex;
   flex-wrap: wrap;
}

.psnal_data .bio-data h6 {
   margin: 0;
   color: var(--bg-gray);
   /* font-weight: 600; */
}

.psnal_data .bio-data>div {
   flex: 0 0 50%;
   margin-bottom: 20px;
}

.psnal_data .skill-data>div {
   flex: 0 0 33%;
   margin-bottom: 20px;
}

.psnal_data .rating-data section {
   display: flex;
   flex-wrap: wrap;
}

.psnal_data .rating-data section>* {
   flex: 1 1 25%;
}

.psnal_data .rating-data section.pv_ratings>* {
   flex: 1 1 100%;
}

.fa-star.rated {
   color: var(--bg-orange);
}

.pv_ratings {
   margin-bottom: 2rem;
   border-bottom: 1px solid #eee
}

.pv_ratings:last-child {
   margin-bottom: 0;
   border-bottom: none
}

.pv_ratings h6 {
   margin-bottom: 0;
}

.see-all {
   float: right;
   color: var(--bg-primary);
   text-decoration: underline;
   font-size: 90%;
   cursor: pointer;
}


/* for login */
.login-section {
   /* position: absolute; */
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: center;
   margin-top: 5%;
}

.login-section .card {
   width: 100%;
   max-width: 350px;
   padding: 1rem;
   border-radius: 15px;
   box-shadow: 0 0 3px #ccc;
}

.login-section h3 {
   text-align: center;
   margin-bottom: 2rem;
}

.login-section .form-control {
   height: 40px;
   background-color: #fff;
}

.login-section button {
   margin-top: 1.5rem;
   width: 100%;
   height: 45px;
}


#modal {
   position: fixed;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   background: rgba(0, 0, 0, 0.4);
   padding-top: 3rem;
   z-index: 11;
}

#modal-container {
   padding: 2rem;
   border-radius: 10px;
   max-width: 1000px;
   min-width: 300px;
   margin: auto;
}

.modal-content {
   background: none;
   border: none;
   align-items: center;
   max-height: 100%;
   overflow: auto;
}

.modal-scroll-h {
   /* max-height: 360px; */
   overflow: auto;
}

#modal .see-all {
   display: none;
}

#modal .psnal_data ._title {
   border-bottom: 1px solid #eee;
   padding-bottom: 16px;
   margin: 0;
}


footer,
.dfooter {
   padding: 2rem 0;
   background-color: var(--bg-body3);
   box-shadow: 2px 0px 6px var(--bg-shadow);
   margin-top: 3rem;
}

.footer {
   text-align: center;
   color: #000;
}

.dfooter {
   background: transparent;
   box-shadow: none;
   font-weight: 500;
}

.crt-form {
   text-align: center;
}

.crt-user {
   max-width: 400px;
   width: 100%;
   display: inline-block;
   text-align: left;
   /* margin: auto; */
}

.crt-user .card-body {
   padding: 2.5rem;
}

.crt-user label {
   font-weight: 600;
}

.crt-user button.btn-orange {
   margin-top: 2.5rem;
   width: 100%;
}

.edt-user {
   max-width: 700px;
   text-align: left;
}

.more_icon {
   font-size: 18px;
   color: gray;
   cursor: pointer;
}

.edt_hdl {
   position: relative;
}

.edt_pnl {
   list-style: none;
   margin: 0;
   box-shadow: 0px 0px 3px #ccc;
   border-radius: 5px;
   background-color: #fff;
   z-index: 1;
   display: none;
   padding: 0;
   left: 0;
   top: 0;
   position: absolute;
   cursor: pointer;
   margin-top: 10px;
}

.edt_hdl:hover>.edt_pnl {
   display: block;
}

.edt_pnl li .fa {
   padding: 10px;
}

.edt_pnl li:hover {
   background-color: #eee;
}


.pymt_status {
   text-transform: capitalize;
}

.pymt_status i {
   border-radius: 10px;
   padding: 1px 10px;
   border: 1px solid;
   width: 90px;
   display: inline-block;
   text-align: center;
}

.pymt_status span.c,
.pymt_status i.c {
   color: green;
   border-color: green;
}

.pymt_status span.f,
.pymt_status i.f {
   color: red;
   border-color: red;
}

.pymt_status span.p,
.pymt_status i.p {
   color: gray;
   border-color: gray;
}

.pymt_status span.o {
   color: var(--bg-orange);
   border-color: var(--bg-orange);
}

.complaint_msg {
   max-width: 500px;
}

.complaint_msg div {
   max-height: 50px;
   overflow: hidden;
}

.profil-cht {
   display: flex;
   justify-content: flex-start;
   padding: 10px;
   position: relative;
   border-top: 1px solid var(--bg-offlightwhite);
}

.profil-cht.bm {
   border-bottom: 1px solid var(--bg-offlightwhite);
}

.profil-ticket {
   flex-direction: column;
   align-items: center;

}

.profil-ticket div:nth-child(2) {
   align-items: center;
}

.profil-ticket div:first-child {
   min-height: 65px;
   min-width: 65px;
   max-height: 65px;
   max-width: 65px;
}

.cht-badge {
   display: inline-block;
   padding: 1px 8px 3px;
   border-radius: 10px;
   color: #fff;
   text-transform: lowercase;
   margin-left: 6px;
   vertical-align: text-top;
}

.cht-badge.worker {
   background-color: var(--bg-orange);
}

.cht-badge.client {
   background-color: var(--bg-primary);
}

.cht-badge.red {
   background-color: red;
}

.cht_list {
   border-right: 1px solid var(--bg-offlightwhite);
}

.lv_cht_box {
   height: 100%;
   width: 100%;
   min-height: 400px;
   max-height: 500px;
   padding: 1.25em;
   overflow: auto;
}

.lv_cht_box>div {
   margin-bottom: 3px;
}

.lv_cht_nm {
   font-weight: 600;
   display: flex;
   justify-content: flex-end;
   position: absolute;
   right: 0;
   margin-right: 9px;
}

.chat_row:hover {
   cursor: default;
   background-color: #f7f1f1
}

.lv_cht_box ._cht1 span,
.lv_cht_box ._cht2 span {
   border-radius: 3px;
   padding: 3px 10px;
   margin-bottom: 5px;
   max-width: 60%;
   font-weight: 500;
   font-size: 94%;
   display: inline-block;
   text-align: left;
   word-break: break-all;
}


.lv_cht_box ._cht2 span::first-letter {
   text-transform: uppercase;
}

.lv_cht_box ._cht1 span {
   background-color: var(--bg-body3);
}

.lv_cht_box ._cht2 {
   text-align: right;
   position: relative;
}

._cht2 .fa-check {
   font-size: 10px;
}

.lv_cht_box ._cht2 span {
   background-color: rgba(179, 218, 230, 0.678);
   color: #000
}

.lv_cht_box ._cht2 span.left_chat {
   background: ghostwhite;
   color: red;
   font-size: 80%;
   text-transform: lowercase;
}

.lv_cht_box ._cht2 span.left_chat::first-letter {
   text-transform: lowercase;
}

.livecht_table tr {
   background-color: #fff !important;
   cursor: default;
}

.livecht_table tr:hover {
   background-color: #eee9 !important;
}

.cht_time {
   font-size: 70%;
   color: var(--bg-gray);
   display: block;
   text-align: right;
}

.cht_time_indica {
   text-align: center;
   position: sticky;
   top: -20px;
   z-index: 1;
}

.cht_time_indica i {
   padding: 3px 10px;
   font-size: 80%;
   background: wheat;
   display: inline-block;
   border-radius: 10px
}

.respndr {
   font-size: 80%;
   margin-bottom: -10px;
   display: none;
   color: var(--bg-orange);
   position: absolute;
   right: 0;
   bottom: 0;
   z-index: 1;
}

._cht2:hover .respndr {
   display: block;
}

.col_data_row>div {
   padding: 10px 1rem;
}

.col_data_row>div[class*="col-md-5"] {
   color: gray;
   font-weight: bold;
}

.profile_pix {
   height: 100%;
   width: 100%;
   max-height: 200px;
   max-width: 200px;
}

.flash_mgs {
   background-color: var(--bg-white);
   color: var(--bg-red);
   position: fixed;
   padding: 5px 1rem;
   border-radius: 7px;
   transition: all .3s ease-in-out;
   z-index: 111;
   box-shadow: 0px 0px 5px var(--bg-shadow);
}

.h-inherit {
   min-height: inherit;
}

.error {
   color: red !important;
}

.spnsr_fnt {
   flex: 0 0 70% !important;
}

.spnsr_fnt h6 {
   font-size: 18px;
}

.spnsr_fnt div:nth-child(2) {
   font-size: 1.4rem;
   font-weight: bold;
}

.h_tooltips {
   position: absolute;
   left: 0;
   top: 0;
   margin-top: 17px;
   box-shadow: 0px 0px 6px var(--bg-shadow);
   padding: 4px 5px;
   display: none;
   background-color: #fff;
   cursor: text;
   border-radius: 10px;
}

.h_tooltips .nm_sexxtion {
   padding-bottom: 0 !important;
   align-items: center;
   cursor: text;
}

.h_tooltips .nm_sexxtion>div:nth-child(2) {
   margin-top: 6px;
}

.c-link:hover {
   text-decoration: underline;
   cursor: pointer;
}

.job_tooltip {
   right: unset;
   left: unset;
   /* margin-right: 255px; */
   margin-top: 35px;
   z-index: 1;
}

.job_addr {
   width: 100%;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   display: block;
}

.job_tooltip::before {
   content: unset;
}

.c-link:hover>.h_tooltips {
   display: block !important;
}

.sc_plugin {
   text-align: center;
   background-color: #eee;
}

.iframe-fb-container {
   text-align: center;
   width: 50%;
   height: 500px;
   display: inline-block;
}

.iframe-fb {
   width: 100%;
   height: 100%;
   overflow: hidden;
}

.fb-page,
.fb-page span,
.fb-page span iframe[style] {
   max-width: 100% !important;
}

iframe#fbc_id html#facebook {
   color: #eee;
}

iframe#fbc_id #facebook ._2p3a[style] {
   width: 100% !important;
}

.cert_row {
   padding-top: 10px;
   padding-bottom: 10px;
   border-bottom: 1px solid var(--bg-eee);
}

.cert_row:last-child {
   border: none;
}

.cert_img {
   width: 50px;
   height: 50px;
   display: inline-block;
   position: relative;

}

.cert_img:first-child {
   margin-right: 1rem;
}

.cert_img img {
   max-width: 100%;
   max-height: 100%;
}

.cert_menu {
   position: absolute;
   top: 0;
   left: 0;
   background-color: #fff;
   list-style: none;
   z-index: 1;
   box-shadow: 0px 0px 4px var(--bg-shadow);
   padding: 0;
   border-radius: 5px;
   display: none;
   margin-top: 20px;
}

.cert_img:hover>.cert_menu {
   display: block;
}

.cert_menu li {
   padding: 5px;
   text-align: left;
   color: #000;
   cursor: pointer;
}

.cert_menu li a {
   color: inherit;
   text-decoration: none;
}

.cert_menu li:hover {
   background-color: var(--bg-offlightwhite);
   color: var(--bg--lightBlue);
}

.short_text {
   text-overflow: ellipsis;
   display: block;
   width: calc(100% - 20%)
}

.date-picker-parent {
   position: absolute;
   top: 0px;
   background-color: var(--bg-body3);
   width: 100%;
   min-height: 600px;
   text-align: right;
   z-index: 1;
}

.MuiPaper-elevation2 {
   box-shadow: none !important;
}

.job-srch-icon {
   transform: rotate(90deg);
   position: absolute;
   margin-left: 20px;
}


.drp-dwn-menu {
   background: #fff;
   border-radius: 5px;
   padding: 0;
   list-style: none;
   box-shadow: 1px 1px 3px #ccc;
   /* z-index: 9999; */
}

.drp-dwn-menu li {
   padding: 5px 10px;
   cursor: pointer;
}

.drp-dwn-menu li:hover {
   background-color: #eee;
   font-weight: bold;
}


















@media(min-width:768px) {

   .mbl_ctl>div:nth-child(2) {
      display: none;
   }
}

@media(max-width:991px) {
   .mt-1X991 {
      margin-top: 1rem;
   }
}

@media(max-width:767px) {
   .main-page>div:first-child {
      position: absolute;
      width: 100%;
      background: rgba(0, 0, 0, .2);
      max-width: 100%;
      height: 100%;
      z-index: 11;
   }

   .filter-control {
      max-width: 100% !important;
   }

   .card_filter {
      text-align: left;
      margin-top: 10px;
   }

   .card_title {
      text-align: center;
   }

   aside {
      background-color: var(--bg-primary);
      height: 100%;
      box-shadow: none;
      max-width: 300px;
   }

   .mbl_ctl>div:first-child {
      display: none;
   }

   .pull_livechat {
      position: absolute;
      height: 100%;
      z-index: 1;
   }
}